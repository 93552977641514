
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import OrderCharacterConfused from '@ancon/wildcat-ui/shared/static/illustrations/order-character-confused.svg';
import HeadingText from '../../features/app/components/HeadingText';
import BodyText from '../../features/app/components/BodyText';
import Button from '../../features/app/components/Button';
import Page from '../../features/layout/components/Page';
import styles from './index.module.scss';
export default function NotFoundPage() {
    const router = useRouter();
    const { t } = useTranslation('common');
    function navigateToHome() {
        router.replace('/');
    }
    return (<Page title={t('notFoundPage.pageTitle')} noIndex>
      <div className={styles.container}>
        <Image src={OrderCharacterConfused} width={120} height={120} alt={t('noRestaurantsNearby')}/>
        <HeadingText as="h2">{t('notFoundPage.title')}</HeadingText>
        <BodyText color="body-1">{t('notFoundPage.message')}</BodyText>
        <div className={styles.footer}>
          <Button variant="secondary" onClick={navigateToHome}>
            {t('notFoundPage.goToHome')}
          </Button>
        </div>
      </div>
    </Page>);
}

    async function __Next_Translate__getServerSideProps__1933f98dc52__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[...not-found]/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1933f98dc52__ as getServerSideProps }
  